import React, { useEffect } from "react"
import useSound from 'use-sound';
import { Helmet } from "react-helmet"
import * as Orb01 from "../scss/pages/404.orb01.module.scss"
import * as Orb02 from "../scss/pages/404.orb02.module.scss"

export default () => {
  // const [play, { pause }] = useSound('/mp3/404-sound.mp3', { volume: 0.5});
  const isBrowser = () => typeof window !== "undefined"
  
  // useEffect(()=> {
  //   if(isBrowser){
  //     xfiles();
  //   }
  // }, [isBrowser])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Barkhaus - 404</title>
      </Helmet>
      <div className="relative">
        <video width="100%" height="auto" autoPlay={true} loop={true} muted={true} playsInline={true}>
          <source src="/mp4/bruss_1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className={"fixed block h-screen w-screen top-0 left-0 z-3000 pointer-events-none"}>
          <div className={Orb01.orb}>
            <div className={Orb01.xaxis}>
              <div className={Orb01.yaxis}>
                <div className={Orb01.scaleX}>
                  <div className={Orb01.scaleY}>
                    <div className={Orb01.opacity}>
                      <div className={Orb01.rotation}>
                        <img src={'/images/team/404.png'} alt="" className={'orb'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={Orb02.orb}>
            <div className={Orb02.xaxis}>
              <div className={Orb02.yaxis}>
                <div className={Orb02.scaleX}>
                  <div className={Orb02.scaleY}>
                    <div className={Orb02.opacity}>
                      <div className={Orb02.rotation}>
                        <img src={'/images/team/404.png'} alt="" className={'orb'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <audio
            className="fixed bottom-10 left-10 bg-transparent"
            autoPlay
            controls
            src="/mp3/404-sound.mp3">
                Your browser does not support the
                <code>audio</code> element.
        </audio>
      </div>
      
    </>
  )
}
